import _findIndex from 'lodash/findIndex';
import React from 'react';
import tw, { css } from 'twin.macro';
import { groupMetrics } from './utils';
import { AnimatePresence, motion } from 'framer-motion';
import IndButton from '../atoms/indicator-button';
import ArrowRight from '../../images/icons/arrow_right_alt.inline.svg';
import CloseIcon from '../../images/icons/close.inline.svg';

const IndicatorButton = motion(IndButton);

const MetricLink = ({ metric, className, isActive, onClick }) => (
  <button
    css={[
      tw`text-center text-brand-purple-800 w-full leading-tight flex items-center justify-center focus:outline-none`,
      isActive && tw`text-brand-green font-bold`,
    ]}
    className={className}
    onClick={onClick}
  >
    {metric.name}
    {isActive ? <CloseIcon tw="ml-2" /> : <ArrowRight tw="ml-2" />}
  </button>
);

const MapMetric = ({ metric, state, onlyChild, onClick }) => (
  <motion.div
    variants={{
      out: { opacity: 0, y: 20 },
      in: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: 20 },
    }}
    css={[
      css`
        &:after {
          content: '';
          ${tw`border-b border-brand-green w-9/12 mx-auto block my-2`}
        }
      `,
    ]}
  >
    {metric.parent ? (
      <>
        <p tw="font-semibold text-brand-purple-800 text-center">
          {metric.parent}
        </p>
        <ul>
          {metric.items.map((item) => (
            <MetricLink
              key={item.id}
              metric={item}
              isActive={
                (state.metricId && state.metricId === item.id) || onlyChild
              }
              onClick={() => onClick(item.id)}
            />
          ))}
        </ul>
      </>
    ) : (
      <MetricLink
        tw="font-semibold"
        metric={metric}
        isActive={(state.metricId && state.metricId === metric.id) || onlyChild}
        onClick={() => onClick(metric.id)}
      />
    )}
  </motion.div>
);

const MapIndicators = ({ state, indicators, toggle }) => {
  const isCurrent = ({ slug }) => state.current === slug;

  const currentIndex = _findIndex(indicators, isCurrent);
  const current = currentIndex !== -1 ? indicators[currentIndex] : null;

  return (
    <>
      <AnimatePresence>
        {current && (
          <motion.div
            initial="out"
            animate="in"
            exit="exit"
            variants={{
              exit: {
                height: 0,
                overflow: 'hidden',
                transition: { when: 'afterChildren' },
              },
            }}
          >
            <IndicatorButton
              item={current}
              index={currentIndex}
              tw="block -mt-4 mx-auto w-44 h-44 visible 2xl:(w-52 h-52)"
              variants={{
                out: { scale: 0 },
                in: { scale: 1 },
                exit: { scale: 0 },
              }}
              Icon={null}
              onClick={() => toggle(current.slug)}
            >
              <CloseIcon
                css={[
                  tw`block w-4 h-4 text-white text-opacity-50 absolute left-1/2 -ml-2 mt-2`,
                  tw`2xl:(mt-4 w-6 h-6 -ml-3)`,
                ]}
              />
            </IndicatorButton>
            <motion.div
              tw="py-6 2xl:pt-10"
              transition={{ staggerChildren: 0.2 }}
            >
              {groupMetrics(current.metrics).map((metric) => (
                <MapMetric
                  key={metric.id || metric.parent}
                  metric={metric}
                  state={state}
                  onlyChild={current.metrics.length === 1}
                  onClick={(id) =>
                    toggle(
                      current.slug,
                      current.metrics.length === 1 ? null : id
                    )
                  }
                />
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!state.current && (
          <motion.div>
            {indicators.map((item, index) => (
              <IndicatorButton
                key={`indicators-list-item--${item.slug}`}
                item={item}
                index={index}
                tw="block -mt-4 mx-auto w-32 h-32 visible 2xl:(w-36 h-36)"
                onClick={() => toggle(item.slug)}
                variants={{
                  out: {
                    y: 20,
                    opacity: 0,
                  },
                  in: {
                    y: 0,
                    opacity: 1,
                  },
                  exit: {
                    scale: 0,
                  },
                }}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MapIndicators;
