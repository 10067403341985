import React from 'react';
import tw from 'twin.macro';
import ArrowRight from '../../images/icons/arrow_right_alt.inline.svg';

const MetricText = ({ text, className, showArrow = false }) => {
  return (
    <div
      tw="max-w-prose text-brand-purple-800 text-sm 2xl:text-base"
      className={className}
    >
      {(text || '').split(' -- ').map((line) => (
        <p key={line} tw="mb-2" css={[showArrow && tw`pl-4 relative`]}>
          {showArrow && (
            <ArrowRight tw="absolute left-0 top-0 w-4 -ml-1 mr-1" />
          )}
          {line}
        </p>
      ))}
    </div>
  );
};

export default MetricText;
