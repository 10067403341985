import mapboxgl from '!mapbox-gl';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import _flatten from 'lodash/flatten';
import { withQueryParams } from 'use-query-params';
import mapQueryConfig from './map-query-config';
import { baseColor, colors } from './config';
import {
  findIndicatorIndex,
  findIndicatorIndexFromMetric,
  findMetricIndex,
} from './utils';
import Donut from './donut';
import Popup from './map-popup';
import tw, { css } from 'twin.macro';
import Bus, { EVENTS } from './bus';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN;

const init = (container, data, indicators) => {
  const map = new mapboxgl.Map({
    container,
    style: 'mapbox://styles/mediaclan/ckr4o1r6c0n6q18jzdfegjsae',
    scrollZoom: false,
  });
  const nav = new mapboxgl.NavigationControl();
  map.addControl(nav, 'top-right');
  let markers = [];
  let pop;

  const showPopup = (ev, query, setQuery) => {
    const node = document.createElement('div');
    const props = ev.features[0]?.properties;
    if (props && props.iso_3166_1 && (!pop || pop.id !== props.iso_3166_1)) {
      if (pop?.popup) {
        pop.popup.remove();
      }
      ReactDOM.render(
        <Popup
          countryId={props.iso_3166_1}
          indicators={indicators}
          data={data}
          query={query}
          showReport={() => setQuery({ ...query, view: props.iso_3166_1 })}
          showMetric={(m) => setQuery({ ...query, m })}
        />,
        node
      );
      const popup = new mapboxgl.Popup({ maxWidth: 'none' })
        .setLngLat(ev.lngLat)
        .setDOMContent(node);
      popup.once('close', () => (pop = null));
      popup.addTo(map);
      popup.getElement().style.visibility = 'hidden';
      setTimeout(() => {
        map.resize();
        popup.getElement().style.visibility = '';
      }, 50);
      pop = { id: props.iso_3166_1, popup };
      return () => {
        popup.remove();
        pop = null;
      };
    }
  };

  const displayMetricValues = (indicatorIndex, metricIndex) => {
    clearMarkers();
    const values = Object.keys(colors).reduce((memo, key) => {
      memo[key] = { ids: [], color: colors[key] };
      return memo;
    }, {});
    data.forEach(([countryData, metricValues]) => {
      if (
        metricValues[indicatorIndex] &&
        typeof metricValues[indicatorIndex][metricIndex] !== 'undefined'
      ) {
        const key = metricValues[indicatorIndex][metricIndex][0];
        if (values[key]?.ids) {
          values[key].ids.push(countryData[0]);
        }
      }
    });
    const fillColor = [
      'case',
      ...Object.values(values).reduce((memo, v) => {
        memo.push(
          ['in', ['get', 'iso_3166_1'], ['literal', JSON.stringify(v.ids)]],
          v.color
        );
        return memo;
      }, []),
      baseColor,
    ];
    updateCountryFill(fillColor);
  };

  const resetMetricValues = () => {
    updateCountryFill(baseColor);
  };

  const updateCountryFill = (fillColor) => {
    if (map.loaded()) {
      map.setPaintProperty('country-boundaries', 'fill-color', fillColor);
    } else {
      map.once('load', () => {
        map.setPaintProperty('country-boundaries', 'fill-color', fillColor);
      });
    }
  };

  const createDonutMarker = ([country, lat, lng], values) => {
    const markerNode = document.createElement('div');
    ReactDOM.render(
      <Donut
        country={country}
        className="map-marker"
        whileHover={{
          scale: 1.5,
        }}
        metrics={values}
      />,
      markerNode
    );
    return new mapboxgl.Marker(markerNode)
      .setLngLat({
        lng,
        lat,
      })
      .addTo(map);
  };

  const clearMarkers = () => {
    markers.forEach((marker) => marker.remove());
    markers = [];
  };

  const overviewMarkers = () => {
    clearMarkers();
    resetMetricValues();
    markers = data.map(([countryData, values]) =>
      createDonutMarker(
        countryData,
        _flatten(values).map(([value]) => value)
      )
    );
  };

  const indicatorMarkers = (indicatorIndex) => {
    clearMarkers();
    resetMetricValues();
    markers = data.map(([countryData, values]) =>
      createDonutMarker(
        countryData,
        values[indicatorIndex].map(([value]) => value)
      )
    );
  };

  const createMarkers = (query) => {
    if (query?.i && query?.m) {
      const indicatorIndex = findIndicatorIndexFromMetric(indicators, query.m);
      const metricIndex = findMetricIndex(indicators, indicatorIndex, query.m);
      if (metricIndex !== -1) {
        return displayMetricValues(indicatorIndex, metricIndex);
      }
    }
    if (query?.i) {
      const index = findIndicatorIndex(indicators, query.i);
      if (index !== -1) {
        if (indicators[index].metrics.length === 1) {
          return displayMetricValues(index, 0);
        }
        return indicatorMarkers(index);
      }
    }
    overviewMarkers();
  };

  return {
    clearMarkers,
    overviewMarkers,
    indicatorMarkers,
    createMarkers,
    showPopup,
    api: map,
  };
};

const MapboxMap = ({ indicators, data, query, setQuery }) => {
  const container = useRef(null);
  const mapbox = useRef(null);
  const closePopupRef = useRef();
  // const popupTimeoutId = useRef(null);

  useEffect(() => {
    if (mapbox.current || !container.current) return;
    mapbox.current = init(container.current, data, indicators);
  });

  useEffect(() => {
    if (closePopupRef.current) {
      try {
        closePopupRef.current();
      } catch (error) {
        // console.log(error);
      }
    }
  }, [query]);

  useEffect(() => {
    if (mapbox.current) {
      const { api, createMarkers, showPopup } = mapbox.current;
      createMarkers(query);
      const onCountryClick = (ev) => {
        // onLeave();
        closePopupRef.current = showPopup(ev, query, setQuery);
      };
      // const onEnter = (ev) => {
      //   onLeave();
      //   popupTimeoutId.current = setTimeout(() => {
      //     showPopup(ev, query, setQuery);
      //   }, 200);
      // };
      // const onLeave = () => {
      //   if (popupTimeoutId.current) {
      //     clearTimeout(popupTimeoutId.current);
      //   }
      // };
      const onResize = () => api.resize();
      // api.on('mouseenter', 'country-boundaries', onEnter);
      // api.on('mouseleave', 'country-boundaries', onLeave);
      api.on('click', 'country-boundaries', onCountryClick);
      Bus.on(EVENTS.resize, onResize);
      return () => {
        // api.off('mouseenter', 'country-boundaries', onEnter);
        // api.off('mouseleave', 'country-boundaries', onLeave);
        api.off('click', 'country-boundaries', onCountryClick);
        Bus.off(EVENTS.resize, onResize);
      };
    }
  }, [query, setQuery]);
  return (
    <div
      ref={container}
      css={[
        css`
          &.mapboxgl-map {
            ${tw`absolute inset-0 overflow-hidden font-body`}
          }
          .map-marker {
            ${tw`w-12 h-12`}
          }
          .mapboxgl-marker:hover {
            z-index: 100;
          }
          .mapboxgl-popup {
            z-index: 100;
          }
          .mapboxgl-popup-close-button {
            ${tw`border border-solid border-current text-brand-purple-800 rounded-full w-5 h-5 leading-none block mt-2 mr-2`}
            &:focus {
              ${tw`outline-none bg-gray-200`}
            }
          }
        `,
      ]}
    ></div>
  );
};

export default withQueryParams(mapQueryConfig, MapboxMap);
