import React, { useEffect, useState, useRef } from 'react';
import { withQueryParams } from 'use-query-params';
import mapQueryConfig from './map-query-config';
import tw, { css } from 'twin.macro';
import Simplebar from 'simplebar-react';
import CountryName from './country-name';
import { AnimatePresence, motion } from 'framer-motion';
import {
  clearScollLock,
  enableScrollLock,
} from '../../utils/use-body-scroll-lock';
import Dot from './traffic-light';
import Variation from './variation';
import CloseIcon from '../../images/icons/close.inline.svg';
import MetricText from './metric-text';
import Legend from './legend';
import { useMediaQuery } from '../common/use-media-query';
import '../../styles/overflow-fade.css';

const getCountryData = (id, data) => {
  return data.find(([countryDef]) => countryDef[0] === id);
};

const HeaderCell = tw.h5`font-bold text-brand-green text-sm truncate md:(mb-4)`;
const Cell = tw.div`py-1 mb-2 md:(p-2 mb-0)`;

const MetricRow = ({ metric, data, withHeaders }) => {
  const isMd = useMediaQuery('(min-width: 768px)');
  const showHeaders = withHeaders || !isMd;
  return (
    <div
      tw="border-b border-status-green mb-4 md:(flex items-start justify-between mb-2)"
      css={[
        css`
          &:last-child {
            border-bottom: 0;
          }
        `,
      ]}
    >
      <Cell tw="md:(flex-grow)">
        {showHeaders && <HeaderCell>Metric Status</HeaderCell>}
        <h5 tw="font-bold leading-tight text-sm flex items-start">
          <span tw="flex-grow mr-4">
            {metric.name}{' '}
            {metric.parent && (
              <span tw="block font-accent font-thin">({metric.parent})</span>
            )}
          </span>{' '}
          <Dot metricKey={data[0]} />
        </h5>
      </Cell>
      <Cell tw="md:(w-1/5 flex-shrink-0 flex-grow-0 pl-4)">
        {showHeaders && <HeaderCell>Variation</HeaderCell>}
        <Variation variation={data[1]} />
      </Cell>
      <Cell tw="md:(order-first w-1/2 flex-shrink-0 flex-grow-0)">
        {showHeaders && <HeaderCell>Summary</HeaderCell>}
        {data[2] && <MetricText showArrow={true} text={data[2]} />}
      </Cell>
    </div>
  );
};

const ReportRow = ({ indicator, data, index }) => {
  const isXl = useMediaQuery('(min-width: 1280px)');
  const showHeader = index === 0 && isXl;
  return (
    <div tw="py-2 mb-4 border-b border-status-amber xl:(flex relative py-0)">
      {showHeader && <HeaderCell tw="absolute top-4 left-0">Areas</HeaderCell>}
      <h3
        tw="p-4 rounded-md text-white font-bold leading-tight mb-4 xl:(w-36 mr-4)"
        css={[
          index % 2 ? tw`bg-brand-green` : tw`bg-brand-purple-800`,
          showHeader ? tw`mt-12` : tw`xl:mt-2`,
        ]}
      >
        {indicator.name}
      </h3>
      <div tw="xl:flex-grow">
        {indicator.metrics.map((metric, i) => (
          <MetricRow
            key={metric.id}
            metric={metric}
            withHeaders={i === 0 && (!isXl || index === 0)}
            data={data[i]}
          />
        ))}
      </div>
    </div>
  );
};

const FullReport = ({ query, setQuery, data, indicators }) => {
  const [countryData, setCountryData] = useState(null);
  const ref = useRef(null);

  const close = () => {
    setQuery({
      ...query,
      view: undefined,
    });
  };
  useEffect(() => {
    if (query.view) {
      const _data = getCountryData(query.view, data);
      if (_data) {
        setCountryData(_data);
      } else {
        setCountryData(null);
      }
    } else {
      setCountryData(null);
    }
  }, [query, data]);

  useEffect(() => {
    if (countryData) {
      enableScrollLock(ref.current ? ref.current.getScrollElement() : true);
    }
    return () => clearScollLock();
  }, [countryData]);

  return (
    <AnimatePresence>
      {countryData && (
        <motion.div
          initial="out"
          animate="in"
          exit="out"
          tw="absolute inset-0 w-full"
          css={css`
            z-index: 130;
          `}
        >
          <motion.div
            onClick={close}
            tw="absolute inset-0 bg-brand-purple-800 bg-opacity-80 w-full"
            variants={{ out: { opacity: 0 }, in: { opacity: 1 } }}
          ></motion.div>
          <motion.div
            className="overflow-fade-white"
            tw="bg-white w-11/12 mx-auto absolute bottom-0 rounded-t-lg lg:(rounded-tr-none) xl:px-4"
            css={[
              css`
                left: ${100 / 24}%;
                &:before {
                  ${tw`rounded-t-lg`}
                }
                @media (min-width: 1280px) {
                  width: 1200px;
                  left: 50%;
                  margin-left: -600px;
                }
              `,
            ]}
            variants={{
              out: { y: '100%' },
              in: { y: 0 },
            }}
          >
            <div tw="hidden absolute bottom-full right-0 rounded-t-lg bg-white pt-2 pr-12 flex-wrap lg:flex xl:pt-4 xl:pr-20 xl:pl-12">
              <Legend tw="px-2" />
            </div>
            <Simplebar
              autoHide={true}
              ref={ref}
              css={[
                tw`overflow-y-auto overflow-x-hidden`,
                css`
                  max-height: 80vh;
                  .simplebar-scrollbar:before {
                    ${tw`bg-purple-200`}
                  }
                `,
              ]}
            >
              <div tw="p-6 xl:py-8">
                <CountryName countryId={query.view} tw="mb-4">
                  <span tw="font-thin">: Summary of key information</span>
                </CountryName>
                {indicators.map((indicator, index) => (
                  <ReportRow
                    key={`${indicator.slug}_row`}
                    indicator={indicator}
                    index={index}
                    data={countryData[1][index]}
                  />
                ))}
              </div>
            </Simplebar>
            <button
              tw="absolute border p-1 border-brand-purple-800 rounded-full text-brand-purple-800 focus:outline-none bottom-full right-0 mb-1 mr-1 xl:(mb-3 mr-2)"
              onClick={close}
            >
              <CloseIcon tw="w-4 h-4" />
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default withQueryParams(mapQueryConfig, FullReport);
