import React from 'react';
import Seo from '../components/common/seo';
import { graphql } from 'gatsby';
import MapContainer from '../components/map/map-container';

const MapPage = ({
  data: {
    strapi: { map: data },
  },
}) => {
  return (
    <>
      <Seo {...data.seo} />
      <MapContainer />
    </>
  );
};

export const query = graphql`
  query MapQuery {
    strapi {
      map {
        seo {
          ...Seo
        }
      }
    }
  }
`;

export default MapPage;
