import React from 'react';
import { metrics } from './config';
import Dot from './traffic-light';
import 'twin.macro';

const LegendItem = ({ item, className }) => (
  <div tw="flex items-center mb-2" className={className}>
    <Dot metricKey={item.key} />
    <p tw="text-sm leading-none ml-2 font-semibold text-brand-purple-800 select-none">
      {metrics[item.key]}
      {item.count && (
        <>
          :&nbsp;<b>{item.count}</b>
        </>
      )}
    </p>
  </div>
);

const Legend = ({ items, className }) => {
  const _items = items || Object.keys(metrics).map((key) => ({ key }));
  return (
    <>
      {_items.map((item) => (
        <LegendItem
          key={`${item.key}_legend`}
          item={item}
          className={className}
        />
      ))}
    </>
  );
};

export default Legend;
