import _findIndex from 'lodash/findIndex';
// import _debounce from 'lodash/debounce';
import tw, { css } from 'twin.macro';
import React, { useState, useEffect, useRef } from 'react';
import { withQueryParams } from 'use-query-params';
import Simplebar from 'simplebar-react';
import MapIndicators from './map-indicators';
import MapIndicatorsNav from './map-indicators-nav';
import mapQueryConfig from './map-query-config';
import '../../styles/overflow-fade.css';

const DEFAULT_STATE = { prev: null, current: null, next: null, metricId: null };

const MapFilters = ({ query, setQuery, indicators }) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const ref = useRef();
  const getIndicatorForMetric = (metricId) => {
    return indicators.find((indicator) =>
      indicator.metrics.some((metric) => metric.id === metricId)
    );
  };
  const isValidIndicator = (slug) =>
    indicators.some((indicator) => indicator.slug === slug);
  const isValidMetric = (id) =>
    indicators.some((indicator) =>
      indicator.metrics.some((metric) => metric.id === id)
    );
  const toggle = (indicatorSlug, metricId) => {
    const i = isValidIndicator(indicatorSlug) ? indicatorSlug : undefined;
    const m = isValidMetric(metricId) ? metricId : undefined;
    if (m) {
      if (query.m && query.m === m) {
        return setQuery({ i: query.i, m: undefined });
      }
      const _i = getIndicatorForMetric(m).slug;
      return setQuery({ i: _i, m });
    }
    if (i) {
      if (query.i && query.i === i) {
        return setQuery({ i: undefined, m: undefined });
      }
      return setQuery({ i, m: undefined });
    }
    return setQuery({ i: undefined, m: undefined });
  };
  useEffect(() => {
    if (query.i) {
      const current = _findIndex(indicators, ({ slug }) => slug === query.i);
      if (current === -1) {
        setState(DEFAULT_STATE);
        return;
      }
      const prev =
        indicators[
          indicators[current - 1] ? current - 1 : indicators.length - 1
        ].slug;
      const next = indicators[indicators[current + 1] ? current + 1 : 0].slug;
      setState({
        prev,
        next,
        current: indicators[current].slug,
        metricId: query.m,
      });
    } else {
      setState(DEFAULT_STATE);
    }
  }, [query, indicators]);
  return (
    <div tw="h-full absolute w-full left-0 top-0 bottom-0 flex flex-col pt-4">
      <div tw="flex-grow relative w-full" className="overflow-fade-white">
        {!state.current && (
          <p tw="absolute top-0 w-full font-bold text-brand-green text-center text-sm flex-grow-0 z-30 2xl:(text-base top-8)">
            indicators
          </p>
        )}
        <Simplebar
          autoHide={true}
          ref={ref}
          css={[
            tw`overflow-y-auto overflow-x-hidden absolute inset-0 w-full h-full`,
            css`
              .simplebar-scrollbar:before {
                ${tw`bg-purple-200`}
              }
            `,
          ]}
        >
          <div tw="py-12 px-4 2xl:(pt-24)">
            <MapIndicators
              state={state}
              indicators={indicators}
              toggle={toggle}
            />
          </div>
        </Simplebar>
      </div>
      <MapIndicatorsNav
        tw="flex-grow-0 flex-shrink-0 relative"
        state={state}
        indicators={indicators}
        toggle={toggle}
      />
    </div>
  );
};

export default withQueryParams(mapQueryConfig, MapFilters);
