import React from 'react';
import { colors } from './config';
import { motion } from 'framer-motion';
import { css, theme } from 'twin.macro';

const RADIUS = 15;

const angleInRadians = (angleInDegrees) =>
  (angleInDegrees - 90) * (Math.PI / 180.0);

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const a = angleInRadians(angleInDegrees);
  return {
    x: centerX + radius * Math.cos(a),
    y: centerY + radius * Math.sin(a),
  };
};

const arc = (x, y, radius, startAngle, endAngle) => {
  const fullCircle = endAngle - startAngle === 360;
  const start = polarToCartesian(x, y, radius, endAngle - 0.01);
  const end = polarToCartesian(x, y, radius, startAngle);
  const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
  ];

  if (fullCircle) d.push('z');
  return d.join(' ');
};

const Donut = ({ country, metrics = [], whileHover, className, hideText }) => {
  const count = {};
  let total = 0;
  let angle = 0;
  metrics.forEach((value) => {
    if (count[value]) {
      count[value] = count[value] + 1;
    } else {
      count[value] = 1;
    }
    total += 1;
  });
  const segments = Object.keys(count).map((key) => {
    const _ang = (360 / total) * count[key];
    const segment = {
      value: count[key],
      path: arc(21, 21, RADIUS, angle, angle + _ang),
      color: colors[key],
    };
    angle += _ang;
    return segment;
  });

  return (
    <motion.svg
      className={className}
      viewBox="0 0 42 42"
      transition={{ staggerChildren: 0.1 }}
      initial="out"
      animate="in"
      whileHover={whileHover}
    >
      {segments.map((segment) => (
        <motion.path
          fill="none"
          key={`${country}_${segment.color}`}
          stroke={segment.color}
          strokeWidth={7}
          d={segment.path}
          tw="origin-center"
          variants={{
            out: {
              pathLength: 0,
            },
            in: {
              pathLength: 1,
            },
          }}
        ></motion.path>
      ))}
      {!hideText && (
        <text
          x="50%"
          y="50%"
          css={[
            css`
              font-size: 0.6em;
              font-weight: bold;
              line-height: 1;
              transform: translateY(0.4em);
              text-anchor: middle;
              fill: ${theme`colors.gray.600`};
            `,
          ]}
        >
          {country}
        </text>
      )}
    </motion.svg>
  );
};

export default Donut;
