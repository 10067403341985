import React from 'react';
import Indy from '../atoms/indicator-button';
import tw, { css } from 'twin.macro';
import { AnimatePresence, motion } from 'framer-motion';
import ArrowBack from '../../images/icons/arrow_back.inline.svg';
import ArrowForward from '../../images/icons/arrow_forward.inline.svg';

const IButton = motion(Indy);

const IndicatorButton = ({ toggle, item, index, className }) => (
  <IButton
    item={item}
    index={index}
    tw="absolute bottom-0 text-xs w-24 h-24"
    variants={{
      in: { y: 0 },
      out: { y: 400 },
      exit: { y: 400 },
    }}
    className={className}
    Icon={null}
    onClick={() => toggle(item.slug)}
  />
);

const arrowStyle = tw`absolute w-4 h-4 pointer-events-none top-14 text-brand-purple-800`;

const IndicatorsNav = ({ state, indicators, toggle, className }) => {
  const prev = indicators.find((x) => x.slug === state.prev);
  const next = indicators.find((x) => x.slug === state.next);
  return (
    <AnimatePresence>
      {state.current && (
        <motion.div
          tw="relative mb-6 z-50 h-28 ml-6 pt-4 -mr-4"
          css={[
            css`
              @media (min-height: 640px) {
                ${tw`mb-10`}
              }
              @media (min-height: 768px) {
                ${tw`mb-12`}
              }
              @media (min-height: 1024px) {
                ${tw`mb-20`}
              }
            `,
          ]}
          initial="out"
          animate="in"
          exit="exit"
          variants={{
            exit: {
              transition: {
                when: 'afterChildren',
              },
            },
            in: {
              transition: {
                when: 'beforeChildren',
              },
            },
          }}
          transition={{ staggerChildren: 0.2 }}
          className={className}
        >
          <ArrowBack tw="right-full" css={arrowStyle} />
          <IndicatorButton toggle={toggle} item={prev} index={0} tw="left-0" />
          <button
            tw="text-sm font-bold block w-full text-brand-purple-800 text-center mt-10 focus:outline-none hover:underline"
            onClick={toggle}
          >
            indicators
          </button>
          <IndicatorButton toggle={toggle} item={next} index={1} tw="right-0" />
          <ArrowForward tw="left-full" css={arrowStyle} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default IndicatorsNav;
