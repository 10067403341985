import React from 'react';
import Donut from './donut';
import {
  findIndicatorIndex,
  findIndicatorIndexFromMetric,
  findMetricIndex,
  getCountryMetrics,
  groupMetricValues,
} from './utils';
import tw, { css } from 'twin.macro';
import Simplebar from 'simplebar-react';
import CountryName from './country-name';
import Dot from './traffic-light';
import MetricText from './metric-text';
import Legend from './legend';
import ArrowRight from '../../images/icons/arrow_forward.inline.svg';

const PopupWrapper = ({ children, className }) => (
  <Simplebar
    autoHide={true}
    className={className}
    css={[
      tw`overflow-y-auto overflow-x-hidden bg-white font-body`,
      css`
        max-height: 60vh;
        max-width: 80vw;
        .simplebar-scrollbar:before {
          ${tw`bg-purple-200`}
        }
      `,
    ]}
  >
    {children}
  </Simplebar>
);

const PopupSubtitle = tw.h5`text-sm font-bold leading-tight text-brand-purple-800`;
const PopupAccentTitle = tw.h4`font-bold text-brand-green text-sm leading-none mb-4`;
const PopupCaption = tw.h5`text-sm font-thin leading-none text-brand-purple-800`;

const ReportButton = ({ showReport, className }) => (
  <button
    tw="font-bold text-sm bg-brand-purple-800 px-6 py-2 rounded-md text-white flex items-center mx-auto mt-4"
    className={className}
    onClick={showReport}
  >
    <span>view country report</span> <ArrowRight tw="ml-2 w-4 h-4" />
  </button>
);

const MetricSummary = ({ metric }) => {
  const value = metric.data[0];
  const text = metric.data[2];
  return (
    <div tw="flex items-start">
      <Dot metricKey={value} />
      <div tw="ml-2">
        <PopupSubtitle
          tw="flex items-end flex-wrap"
          as={metric.parent ? 'div' : 'h4'}
        >
          {metric.name}
          {metric.parent && (
            <PopupCaption tw="ml-1 pl-1 border-l">{metric.parent}</PopupCaption>
          )}
        </PopupSubtitle>
        {text && <MetricText text={text} tw="mt-2" />}
      </div>
    </div>
  );
};

const CountryPopup = ({ countryId, values, showReport }) => {
  const groupedValues = groupMetricValues(values);
  return (
    <PopupWrapper tw="w-96">
      <div tw="flex items-start sm:(pt-4 px-4)">
        <div tw="pr-2 flex-shrink-0 sm:pr-4">
          <CountryName countryId={countryId} />
          <Donut
            country={countryId}
            metrics={values}
            hideText
            tw="w-32 h-32 sm:(w-40 h-40)"
          />
        </div>
        <div>
          <PopupAccentTitle tw="sm:mt-3">Performance Overview</PopupAccentTitle>
          <Legend
            items={Object.keys(groupedValues).map((item) => ({
              key: item,
              count: groupedValues[item],
            }))}
          />
        </div>
      </div>
      <ReportButton showReport={showReport} tw="sm:mb-4" />
    </PopupWrapper>
  );
};

const IndicatorPopup = ({
  indicator,
  metrics,
  countryId,
  showMetric,
  showReport,
}) => {
  return (
    <PopupWrapper tw="w-96">
      <div tw="p-2">
        <PopupAccentTitle tw="px-2">{indicator.name}</PopupAccentTitle>
        <CountryName countryId={countryId} tw="mb-4 px-2" />
        {indicator.metrics
          .map((metric, index) => {
            return {
              ...metric,
              data: metrics[index],
            };
          })
          .map((metric) => (
            <button
              key={`${metric.id}_popup`}
              tw="block text-left w-full focus:outline-none hover:bg-brand-purple-200 p-2"
              onClick={() => showMetric(metric.id)}
            >
              <MetricSummary metric={metric} />
            </button>
          ))}
        <ReportButton showReport={showReport} />
      </div>
    </PopupWrapper>
  );
};

const MetricPopup = ({ countryId, metric, data, showReport }) => {
  const legendItems = [{ key: data[0] }];
  return (
    <PopupWrapper tw="w-96">
      <div tw="p-2">
        <PopupAccentTitle>{metric.name}</PopupAccentTitle>
        <CountryName countryId={countryId} tw="mb-4" />
        <Legend items={legendItems} />
        {data[2] && <MetricText text={data[2]} />}
        <ReportButton showReport={showReport} />
      </div>
    </PopupWrapper>
  );
};

const Popup = ({
  countryId,
  query,
  showReport,
  showMetric,
  data,
  indicators,
}) => {
  if (!countryId) return '';

  if (query.m) {
    const indicatorIndex = findIndicatorIndexFromMetric(indicators, query.m);
    if (indicatorIndex !== -1) {
      const metricIndex = findMetricIndex(indicators, indicatorIndex, query.m);
      if (metricIndex !== -1) {
        return (
          <MetricPopup
            countryId={countryId}
            metric={indicators[indicatorIndex].metrics[metricIndex]}
            showReport={showReport}
            data={getCountryMetrics(
              data,
              countryId,
              indicatorIndex,
              metricIndex
            )}
          />
        );
      }
    }
  }
  if (query.i) {
    const index = findIndicatorIndex(indicators, query.i);
    if (index !== -1) {
      return (
        <IndicatorPopup
          countryId={countryId}
          indicator={indicators[index]}
          showMetric={showMetric}
          showReport={showReport}
          metrics={getCountryMetrics(data, countryId, index)}
        />
      );
    }
  }
  return (
    <CountryPopup
      countryId={countryId}
      values={getCountryMetrics(data, countryId)}
      showReport={showReport}
    />
  );
};

export default Popup;
