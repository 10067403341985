import React from 'react';
import { countryNames } from './config';
import 'twin.macro';

const CountryName = ({ countryId, className, children }) => (
  <h4
    tw="text-xl leading-none font-bold text-brand-purple-800 sm:text-2xl"
    className={className}
  >
    {countryNames[countryId] || countryId}
    {children}
  </h4>
);

export default CountryName;
