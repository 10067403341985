import React from 'react';
import { colors } from './config';
import 'twin.macro';

const Dot = ({ metricKey, className }) => (
  <span
    tw="w-5 h-5 block rounded-full flex-grow-0 flex-shrink-0 border border-gray-300"
    className={className}
    style={{ backgroundColor: colors[metricKey] }}
  ></span>
);

export default Dot;
