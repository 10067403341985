export const metrics = {
  1: 'Good',
  2: 'Room for improvement',
  3: 'Not Good',
  4: 'Early Access Program',
  5: 'Not Authorised / Assessed',
};

export const colors = {
  1: '#50A354',
  2: '#F1B13B',
  3: '#D17771',
  4: '#6D96F8',
  5: '#BABAB8',
};

export const baseColor = '#7FC9CB';

export const countryNames = {
  AT: 'Austria',
  BE: 'Belgium',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IS: 'Iceland',
  IE: 'Ireland',
  IT: 'Italy',
  MK: 'North Macedonia',
  NL: 'Netherlands',
  PL: 'Poland',
  RO: 'Romania',
  RU: 'Russia',
  RS: 'Serbia',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  GB: 'United Kingdom',
  UA: 'Ukraine',
};
