import _findIndex from 'lodash/findIndex';
import _flatten from 'lodash/flatten';

export const findIndicatorIndex = (indicators, indicatorSlug) => {
  return _findIndex(indicators, ({ slug }) => indicatorSlug === slug);
};

export const findMetricIndex = (indicators, indicatorIndex, metricId) => {
  if (indicatorIndex !== -1) {
    return _findIndex(
      indicators[indicatorIndex].metrics,
      ({ id }) => id === metricId
    );
  }
  return -1;
};

export const findMetricById = (indicators, metricId) => {
  let metric;
  indicators.find(({ metrics }) => {
    metric = metrics.find(({ id }) => id === metricId);
    return metric;
  });
  return metric;
};

export const findIndicatorIndexFromMetric = (indicators, metricId) => {
  return _findIndex(indicators, ({ metrics }) =>
    metrics.some((metric) => {
      return metric.id === metricId;
    })
  );
};

export const getCountryMetrics = (
  data,
  countryId,
  indicatorIndex = -1,
  metricIndex = -1
) => {
  const countryData = data.find(([countryDef]) => countryDef[0] === countryId);
  if (countryData) {
    if (indicatorIndex !== -1 && metricIndex !== -1) {
      return countryData[1][indicatorIndex][metricIndex];
    }
    if (indicatorIndex !== -1) {
      return countryData[1][indicatorIndex];
    }
    return _flatten(countryData[1]).map(([value]) => value);
  }
};

export const groupMetricValues = (values) => {
  return values.reduce((memo, value) => {
    if (typeof memo[value] !== 'undefined') {
      memo[value] += 1;
    } else {
      memo[value] = 1;
    }
    return memo;
  }, {});
};

export const groupMetrics = (metrics) => {
  const results = [];
  metrics.forEach((metric) => {
    if (metric.parent) {
      const index = _findIndex(
        results,
        ({ parent }) => parent === metric.parent
      );
      if (index === -1) {
        results.push({
          parent: metric.parent,
          items: [metric],
        });
      } else {
        results[index].items.push(metric);
      }
    } else {
      results.push(metric);
    }
  });
  return results;
};
