import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MapWrapper from './map-wrapper';
import MapDrawer from './map-drawer';
import MapboxMap from './map';
import MapReport from './map-full-report';
import { useEffect } from 'react';
import { Location, globalHistory } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';
import 'twin.macro';

const MapContainer = () => {
  const {
    strapi: { indicators },
  } = useStaticQuery(graphql`
    {
      strapi {
        indicators(sort: "id") {
          metrics {
            id
            name
            parent
          }
          slug
          name
        }
      }
    }
  `);
  const [state, setState] = useState({ data: null });
  const loadData = async function () {
    const { default: data } = await import('../../data/map-data.json');
    setState({ data });
  };
  useEffect(() => {
    if (!state.data) {
      loadData();
    }
  });
  return (
    <Location>
      {({ location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          <MapWrapper>
            {state.data ? (
              <>
                <MapboxMap data={state.data} indicators={indicators} />
                <MapReport indicators={indicators} data={state.data} />
              </>
            ) : (
              <div tw="absolute inset-0 bg-gray-200 text-purple-500 flex items-center justify-center">
                loading&hellip;
              </div>
            )}
            <MapDrawer indicators={indicators} />
          </MapWrapper>
        </QueryParamProvider>
      )}
    </Location>
  );
};

export default MapContainer;
