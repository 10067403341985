import React from 'react';
import Dot from './traffic-light';
import 'twin.macro';

const Variation = ({ variation, defaultText = 'None' }) => {
  return (
    <span tw="flex items-start">
      <Dot tw="mr-2" />
      <span tw="font-accent leading-tight">{variation || defaultText}</span>
    </span>
  );
};

export default Variation;
