import React, { useState } from 'react';
import { motion } from 'framer-motion';
import tw, { css } from 'twin.macro';
import SVGCurve from '../svgs/curve';
import CloseIcon from '../../images/icons/close.inline.svg';
import TuneIcon from '../../images/icons/tune.inline.svg';
import MapFilters from './map-filters';
import { useMediaQuery } from '../common/use-media-query';

const MapDrawer = ({ indicators }) => {
  const [open, setOpen] = useState(false);
  const isLg = useMediaQuery('(min-width: 1024px)');

  return (
    <>
      {open && !isLg && (
        <button
          tw="absolute inset-0 block w-full h-full focus:outline-none"
          aria-label="Close Filters"
          onClick={() => setOpen(false)}
        ></button>
      )}
      <motion.div
        layout
        tw="absolute top-0 left-0 bottom-0 p-4 bg-white flex flex-col z-30"
        css={[
          css`
            width: 280px;
            margin-left: -320px;
            z-index: 125;
            @media (min-width: 1024px) {
              margin-left: 0;
            }
          `,
          open && tw`ml-0`,
        ]}
      >
        <SVGCurve
          tw="absolute top-0 left-full bottom-0 h-full"
          fill="white"
          viewBox="0 0 46 667"
          paths={[
            'M40.9527588 0c3.005778 7.15115356 3.005778 17.2537638 0 30.3078308C19.8783748 121.833692 2.99615479 179.63356 4.14544678 286.364975 5.29473877 393.09639-.48760986 418.738327 33.7001953 511.717758 56.4920654 573.704046 45.258667 625.475815 0 667.033066V0h40.9527588z',
            'M 0 19 c 0 7 0 19 0 42 C 0 125 0 179 0 286 C 0 395 0 418 0 516 C 0 588 0 606 0 667.0331 V 0 h 0 z',
          ]}
        />

        <MapFilters indicators={indicators} />

        {!isLg && (
          <motion.button
            layout
            css={[
              tw`absolute border border-transparent top-0 left-full rounded-full bg-brand-purple-800 text-brand-purple-100 focus:outline-none z-20 p-3 ml-12 mt-2`,
              open &&
                tw`-ml-4 bg-white text-brand-purple-800 border-brand-purple-600 p-1`,
            ]}
            aria-label="Toggle filters' sidebar"
            onClick={() => setOpen(!open)}
          >
            {(open && <CloseIcon />) || <TuneIcon />}
          </motion.button>
        )}
      </motion.div>
    </>
  );
};

export default MapDrawer;
