import React, { useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';
import 'twin.macro';
import Bus, { EVENTS } from './bus';
const getSize = () => {
  const header = document.querySelector('[data-site-header]');
  if (header) {
    return `${window.innerHeight - header.getBoundingClientRect().height}px`;
  }
  return '80vh';
};
const MapWrapper = ({ children }) => {
  const [height, setHeight] = useState();

  useEffect(() => {
    const onResize = _debounce(() => {
      setHeight(getSize());
      Bus.emit(EVENTS.resize);
    }, 100);
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });
  return (
    <div tw="w-full h-screen overflow-hidden relative" style={{ height }}>
      {children}
    </div>
  );
};

export default MapWrapper;
